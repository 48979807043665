const WebSocketClass = {
    ws: null,
    uid:"datav",
    isWebsocketClose:false,
    heartBeatInterval:0,
    init: () => {
        return new Promise((resolve, reject) => {
            WebSocketClass.uid = "datav" + (Math.floor(Math.random() * 900) + 100);
            WebSocketClass.ws = new WebSocket("wss://www.bank.gdtwzc.com/wss");
            WebSocketClass.ws.onopen = WebSocketClass.onopen
            WebSocketClass.ws.onerror = WebSocketClass.onerror
            WebSocketClass.ws.onclose = WebSocketClass.onclose
            WebSocketClass.ws.onmessage = (res) => {
                resolve(res);
            };
            WebSocketClass.isWebsocketClose = true
        })
    },

    onopen: () => {
        console.log('连接成功')
        WebSocketClass.ws.send(
            JSON.stringify({
                type: "login",
                uid: WebSocketClass.uid,
                job: "receive"
            })
        )
        //发送心跳
        WebSocketClass.heartBeatInterval = setInterval(()=>{
            WebSocketClass.findReceive()
        },40000)
    },

    onerror: () => {
        console.log('连接失败')
    },
    findReceive:()=>{
        WebSocketClass.ws.send(
            JSON.stringify({
              type: 'find',
              uid: WebSocketClass.uid
            })
          )
    },
    onsend: (data) => {
        console.log(WebSocketClass.ws, data, 'data')
        WebSocketClass.ws.send(data)
    },
    onclose: () => {
        console.log("Websocket连接关闭,2秒后重连");
        WebSocketClass.heartBeatInterval = null;
        WebSocketClass.isWebsocketClose = true
    }
}

export default WebSocketClass