<template>
  <div class="title">
    <Title :titleInfo="headerData.titleInfo"></Title>
  </div>
  <div
    class="statisc-echarts"
    style="border: 1px solid rgba(35, 132, 222, 0.5)"
  >
    <div
      class="echarts-item"
      v-for="(item, index) in progressList"
      :key="index"
    >
    
      <ProgressLoop  :echartsData="item" ></ProgressLoop>
    </div>
  </div>
</template>

<script setup>
import Title from "./Title.vue";
import ProgressLoop from "./ProgressLoop.vue";
import { reactive, toRefs, watch,computed  } from "vue";
const headerData = reactive({
  titleInfo: {
    icon: require("@/assets/images/health.png"),
    title: ["健康度统计"],
  },
});

let props = defineProps({
  direction: String,
  doing: Object,
});

// const states = reactive({
//   progressList: [
//     {
//       color: "rgba(64, 236, 255, 1)",
//       text: "正常",
//       pert: 50,
//     },
//     {
//       color: "rgba(255, 181, 36, 1)",
//       text: "告罄",
//       pert: 80,
//     },
//     {
//       color: "rgba(255, 37, 37, 1)",
//       text: "异常",
//       pert: 20,
//     },
//   ],
// });
const progressList = computed(() => {
    const progressList = [];
    progressList.push({
      color: "rgba(64, 236, 255, 1)",
      text: "正常",
      pert: 50,
    });
    progressList.push({
      color: "rgba(255, 181, 36, 1)",
      text: "告警",
      pert: 80,
    },);
    progressList.push({
      color: "rgba(255, 37, 37, 1)",
      text: "异常",
      pert: 20,
    });
  if (!props.doing) {
    return progressList;
  }
  // 进行一些数据处理操作
  const newData = [];
  let warning = 0;
  let error = 0;
  let normal = 0;
  let sum = 0;
  Object.keys(props.doing).forEach((key) => {
    if(props.doing[key].health == 'warning'){
        warning++;
    }else if(props.doing[key].health == 'error'){
        error++;
    }else{
        normal++;
    }
    sum++;
  });
  let normalPert = (normal / sum * 100).toFixed(2);
  let warningPert = (warning / sum * 100).toFixed(2);
  let errorPert = (error / sum * 100).toFixed(2);
  // progressList[0].pert = normalPert;
  // progressList[1].pert = warningPert;
  // progressList[2].pert = errorPert;
  //修改异常为正常
  progressList[0].pert = errorPert;
  progressList[1].pert = warningPert;
  progressList[2].pert = normalPert;
  return progressList;
});
// const { progressList } = toRefs(states);
</script>

<style scoped lang="scss">
.title {
  margin-top: 16px;
}
.statisc-echarts {
  width: 100%;
  height: 129px;
  // height: 229px;
  background: rgba(35, 133, 223, 0.15);
  margin-top: 10px;
  padding: 24px 64px 10px 64px;
  // padding: 24px 34px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .echarts-item {
    text-align: center;
  }
}
</style>
