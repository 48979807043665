<template>
  <div class="real-time-wrapper">
    <div class="title">
      <Title :titleInfo="headerData.titleInfo"></Title>
    </div>
    <div class="real-time" style="border: 1px solid rgba(35, 132, 222, 0.5)">
      <div class="reall-time--header">
        <div>编号/工程号</div>
        <div>分类</div>
        <div>地址</div>
        <div>状态</div>
        <div>时间</div>
      </div>
      <div class="reall-time--content--wrapper item-wrapper" @mouseenter="mouseMove" @mouseleave="mouseleave">
      <div class="reall-time--content items" ref="itemsRef">
        <div
          class="reall-time--content-item content-item"
          v-for="(item, index) in recentList"
          :key="index"
          :class="{ 'odd-number': (index + 1) % 2 === 1 }"
        >
          <div class="base-info" >
            <div class="numbering content">{{ item.order_no }}</div>
            <div class="content" :title="item.category_text">
              {{ item.category_text }}
            </div>
            <div class="content" :title="item.name">{{ item.name }}</div>
            <div class="content status" :title="item.status_text" :class="{'red':item.status == 200,'yellow': item.status === 300,'orange':item.status === 400}">
              {{ item.status_text }}
            </div>
            <div class="content" :title="item.start_time">
              {{ item.start_time }}
            </div>
          </div>
          <div class="pert-info">
            <div class="pert-name">完成进度</div>
            <div class="project-progress">
              <el-progress :percentage="item.process" :show-text="false" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script setup>
import Title from "./Title.vue";
import { reactive, computed, ref,watch,onMounted,onUnmounted  } from "vue";
import { nextTick } from "process";
const headerData = reactive({
  titleInfo: {
    icon: require("@/assets/images/health_statisc.png"),
    title: ["实时进度"],
  },
});
const itemsRef = ref(null);

const timer = ref(null);
const myTimeout = ref(null);
const props = defineProps({
  recent: Object,
});
const recentList = computed(() => {
  if (!props.recent) {
    return {};
  }
  //进行一些数据处理操作
  const newData = [];
  Object.keys(props.recent).forEach((key) => {
    newData.push({
      id: props.recent[key].id,
      order_no: props.recent[key].order_no,
      name: props.recent[key].name,
      category_text: props.recent[key].category_text,
      personName: props.recent[key].personName,
      start_time: props.recent[key].start_time,
      end_time: props.recent[key].end_time,
      status: props.recent[key].status,
      status_text: props.recent[key].status_text,
      process: props.recent[key].process,
    });
  });
  return newData;
});
watch(
  () => props.recent,
  (newVal) => {
    //暂停动作和恢复原点
    cancelAnimationFrame(timer.value);
    clearTimeout(myTimeout.value);
    itemsRef.value.scrollTop = 0;
    // 更新recentList的值
    recentList.value = newVal;
    nextTick(()=>{
      start();
    })
  }
);
onMounted(() => {
  if (window.onload) {
    nextTick(() => {
      myTimeout.value = setTimeout(()=>{
        start();
      },5000)
    });
  } else {
    window.addEventListener('load', () => {
      myTimeout.value = setTimeout(()=>{
        start();
      },5000)
    })
  }
  // nextTick(() => {
  //   //获取变量值
  //   setTimeout(() => {
  //     // itemHeight.value = eleRefs.value[0].offsetHeight;
  //     start();
  //   }, 5000);
  // });
});
/**
 * @Description: 鼠标移动事件
 * @Author: admin
 */
 function mouseMove() {
  cancelAnimationFrame(timer.value);
  clearTimeout(myTimeout.value);
}
/**
 * @Description: 鼠标离开事件
 * @Author: admin
 */
function mouseleave() {
  start();
}
//等同于vue2中的destroyed
onUnmounted(() => {
  //清除定时器
  cancelAnimationFrame(timer.value);
  clearTimeout(myTimeout.value);
});
//开启定时器方法
function start() {
  //清除定时器
  cancelAnimationFrame(timer.value);
  clearTimeout(myTimeout.value);
  //定时器触发周期
  MarqueeTest();
  // timer.value = setInterval(MarqueeTest, speed.value);
}
function MarqueeTest() {
  let test1 = itemsRef.value;
  let height = test1.getElementsByClassName("content-item")[0].offsetHeight +6;
  //判断组件是否渲染完成
  if (test1.getElementsByClassName("content-item").length == 0) {
    test1 = itemsRef.value;
  } else {
    //如果列表数量过少不进行滚动
    if (test1.getElementsByClassName("content-item").length <= 3) {
      cancelAnimationFrame(timer.value);
      clearTimeout(myTimeout.value);
      return;
    }
    // test1.style.tranform = "translateY(-" + height + "px)"
    // console.error("test1.style.tranform",test1.style.tranform);
    //组件进行滚动
    test1.scrollTop += 1;
    // console.error("test1.scrollTop", test1.scrollTop);
    
    if(test1.scrollTop >= height){
      //滚了一个了，就节点更换
      //获取组件第一个节点
      let a = test1.childNodes[0];
      //删除节点
      test1.removeChild(a);
      //将该节点拼接到组件最后
      test1.append(a);
      myTimeout.value = setTimeout(()=>{
        timer.value = requestAnimationFrame(MarqueeTest);
      },5000)
        test1.scrollTop = 0;
    }else{
      test1.scrollTop += 1;
      timer.value = requestAnimationFrame(MarqueeTest);
    }

    // //判断滚动条是否滚动到底部
    // if (test1.scrollTop == test1.scrollHeight - test1.clientHeight) {
    //   //获取组件第一个节点
    //   let a = test1.childNodes[0];
    //   //删除节点
    //   test1.removeChild(a);
    //   //将该节点拼接到组件最后
    //   test1.append(a);
    // }
    // myTimeout.value = setTimeout(()=>{
    //   timer.value = requestAnimationFrame(MarqueeTest);
    // },50)
  }
}
</script>


<style lang="scss">
  .real-time-wrapper .el-progress-bar__outer {
  background: rgba(128, 136, 142, 0.4) !important;
}
// /deep/.el-progress-bar__inner {
//   background-color: unset;
//   background-image: linear-gradient(
//     270deg,
//     rgba(77, 255, 223, 1) 0%,
//     rgba(77, 161, 255, 1) 100%
//   );
// }

.real-time-wrapper .el-progress-bar__inner {
  background-color: unset;
  background-image: linear-gradient(
    270deg,
    rgba(77, 255, 223, 1) 0%,
    rgba(77, 161, 255, 1) 100%
  ) !important;
}
.real-time-wrapper .el-progress.red .el-progress-bar__inner{
    background-image: linear-gradient(
    270deg,
    #ed3380 0%,
    rgb(255, 86, 86) 100%
  ) !important;
}
.real-time-wrapper .el-progress.yellow .el-progress-bar__inner{
    background-image: linear-gradient(
    270deg,
    #d8ff01,
    rgb(252, 248, 32) 100%
  ) !important;
}
.real-time-wrapper .el-progress.orange .el-progress-bar__inner{
    background-image: linear-gradient(
    270deg,
    rgb(255, 175, 70) 0%,
    rgb(255, 177, 33) 100%
  ) !important;
}
.real-time-wrapper {
  .title {
    margin-top: 16px;
  }
  .real-time {
    margin-top: 12px;
    width: 100%;
    height: 302px;
    // border: 1px solid rgba(255, 255, 255, 1);
    background: rgba(8, 26, 48, 0.8);
    // padding: 12px;
    box-sizing: border-box;

    .reall-time--header {
      display: flex;
      justify-content: space-between;
      background: rgba(255, 255, 255, 0.1);
      padding: 5px 12px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.7);
      & > div {
        width: calc(100% / 6);
        text-align: center;
        &:nth-child(1) {
          text-align: left;
        }
        &:nth-last-child(1) {
          text-align: right;
        }
      }
    }
    .reall-time--content--wrapper{
      width: 100%;
      height: calc(100% - 30px);
      overflow-y: scroll;
      scrollbar-width: none; /* 隐藏垂直滚动条 */
      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
      }
    }
    .reall-time--content {
      overflow-y: scroll;
      height: 100%;
      overflow: scroll;
      transition: all 0.6s;
      transform: translateZ(0);
      // transform: translateY();
      scrollbar-width: none; /* 隐藏垂直滚动条 */

      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
      }
    }
    
    .reall-time--content-item {
      height: 82px;
      background: rgba(44, 179, 255, 0.1);
      // background: rgba(8, 26, 48, 0.8);
      &.odd-number {
        background: rgba(8, 26, 48, 0.8);
      }
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 20px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      vertical-align: top;
      .base-info {
        padding: 16px 12px;
        display: flex;
        justify-content: space-between;
        .status{
          color: #00deff;
          &.red{
            color: #ea3c3c;
          }
          &.yellow{
            color: yellow;
          }
          &.orange{
            color: orange;
          }
        }
        .content {
          width: calc(100% / 6);
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:nth-child(1) {
            text-align: center;
            // margin-left: 16px;
          }
          &:nth-last-child(1) {
            text-align: right;
          }
        }
        .numbering {
          position: relative;
        }
        .numbering::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          // border-width: 10px;
          left: 10px;
          top: 5px;
          border-top: 5px solid rgba(255, 255, 255, 0);
          border-bottom: 5px solid rgba(255, 255, 255, 0);
          border-left: 5px solid rgba(24, 254, 254, 1);
          border-right: 5px solid rgba(255, 255, 255, 0);
        }
      }
      .pert-info {
        display: flex;
        justify-content: space-between;
        padding: 0 12px 0 12px;
        .pert-name {
          display: inline-block;
          text-align: center;
          flex: 0 1 calc(100% / 6);
        }
        .project-progress {
          flex: 0 1 83.3%;
          height: 26px;
          padding: 8px 0;
        }
      }
    }
  }
}
</style>
<style>
</style>