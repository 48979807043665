import request from './request';

export default {
    get: (url, params) => {
        return request({
            url,
            params,
            method: 'GET'
        })
    },
    post: (url, data) => {
        return request({
            url,
            data,
            method: 'POST'
        })
    }
}