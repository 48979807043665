<template>
  <div class="progress-loop"  :class="{'red':echartsData.text == '异常','yellow': echartsData.text === '告警','blue':echartsData.text === '正常'}">
    <div class="inside"></div>
    <!-- width="120" -->
    <el-progress
      :style="'margin-top: 2px;color:' + echartsData.color + ';'"
      stroke-linecap="square"
      type="circle"
      width="70"
      stroke-width="10"
      :percentage="echartsData.pert"
      :color="echartsData.color"
    />
  </div>
  <span>{{ echartsData.text }}</span>
 
  <div class="none" style="width: 0;height: 0;">
    <svg width="100%" height="100%">
      <defs>
        <linearGradient id="blue" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style="stop-color:rgba(63, 236, 255, 0.5);stop-opacity:1" />
          <stop offset="100%" style="stop-color:rgba(64, 236, 255, 1);stop-opacity:1" />
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div class="none" style="width: 0;height: 0;">
    <svg width="100%" height="100%">
      <defs>
        <linearGradient id="yellow" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style="stop-color:rgba(255, 181, 36, 1);stop-opacity:1" />
          <stop offset="100%" style="stop-color:rgba(255, 181, 36, 0.5);stop-opacity:1" />
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div class="none" style="width: 0;height: 0;">
    <svg width="100%" height="100%">
      <defs>
        <linearGradient id="red" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style="stop-color:rgba(255, 37, 37, 1);stop-opacity:1" />
          <stop offset="100%" style="stop-color:rgba(255, 37, 37, 0.5);stop-opacity:1" />
        </linearGradient>
      </defs>
    </svg>
  </div>
  
</template>

<script setup>
import {} from "vue";
const props = defineProps({
  echartsData: Object,
});

let color = props.echartsData.color;
</script>
<style scoped lang="scss">
/deep/.el-progress-circle__track {
  stroke: rgba(35, 133, 223, 0);
}
.progress-loop.blue /deep/ .el-progress-circle__path{
    stroke: url(#blue); 
}
.progress-loop.yellow /deep/ .el-progress-circle__path{
    stroke: url(#yellow); 
}
.progress-loop.red /deep/ .el-progress-circle__path{
    stroke: url(#red); 
}
/deep/ .el-progress__text {
  color: v-bind(color);
}
.progress-loop {
  width: 70px;
  height: 70px;
  // width: 120px;
  // height: 120px;
  position: relative;
  align-items: center;

  .inside {
    margin: auto auto;
    // width: 50px;
    // height: 50px;
    width: 80%;
    height: 80%;
    border: 3px solid rgba(35, 133, 223, 0.15);
    border-radius: 50%;
    position: absolute;
    // top: 7px;
    // right: 7px;
    // left: 3.5px;
    // bottom: 3.5px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}
span {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 0px;
  color: rgba(255, 255, 254, 1);
  text-align: center;
  vertical-align: bottom;
}
</style>
