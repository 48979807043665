<template>
    <div class="header-updata--date">最新数据更新于{{ updataTime }}</div>
    <div class="header-title">
        <img src="@/assets/images/logo_TW.png" alt="">
        <span>天网智城数据看板</span>
        <!-- <img src="@/assets/images/logo.cd33b850.png" alt=""> -->
        <!-- <span>南方通信建设</span> -->
    </div>
    <div class="header-weather">
        <!-- <div class="weather">
            <img src="@/assets/images/weather.png" alt="">
        </div>
        <div class="templer-pm">
            <div class="templer" style="text-align: center;">
                <div class="templer-number">20</div>
                <div class="templer-unit">℃</div>
            </div>
            <div class="pm">PM2.5 19</div>
        </div> -->
        <div class="now-date">
            <div class="time">{{ nowDateTimeInfo.time }}</div>
            <div class="date">
                <div style="margin-right: 6px;">{{ nowDateTimeInfo.date }}</div>
                <div>|</div>
                <div class="date-text" style="margin-left: 6px;">年月日</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import monent from 'moment';
import { onMounted, reactive, onUnmounted, watch } from 'vue'

let props = defineProps({
    updataTime: String
})

watch(() => props.updataTime, (val) => {
    console.log(val);
})

let nowDateTimeInfo = reactive({
    date: '',
    time: '',
    timer: null
});
onMounted(() => {
    nowDateTimeInfo.timer = setInterval(() => {
        getDate()
        getTime()
    }, 1000)
})
onUnmounted(() => {
    clearInterval(nowDateTimeInfo.timer)
    nowDateTimeInfo.timer = null;
})
const monentFun = () => {
    return monent()
}
// 获取当前日期
const getDate = () => {
    nowDateTimeInfo.date = monentFun().format("YYYY/MM/DD");
}

// 获取当前时间
const getTime = () => {
    nowDateTimeInfo.time = monentFun().format("HH:mm:ss");
}
        
</script>

<style scoped lang="scss">
.header-updata--date{
    margin-top: 24px;
    font-size: 14.69px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.6);
    text-align: left;
    vertical-align: top;
}
.header-title{
    height: 80px;
    margin-left: 5px;
    display: flex;
    width: 517.73px;
    align-items: center;
    img{
        margin-left: 128px;
        width: 55px;
        height: 55px;
    }
    span{
        display: flex;
        margin-left: 20px;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 1.44px;
        color: rgba(255, 255, 255, 1);
        text-align: left;

    }
}
.header-weather{
    display: flex;
    margin-top: 24px;
    color: rgba(255, 255, 255, 1);
    width: 310px;
    justify-content: space-between;
    direction: rtl;
    .weather{
        img{
            width: 46px;
            height: 46px;
        }
    }
    .templer-pm{
        width: 120px;
        .templer{
            display: flex;
            font-size: 28px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 28px;
            color: rgba(255, 255, 255, 1);
            height: 30px;
            justify-content: center;
            .templer-number{
                border-bottom: 1px solid #80888E;
            }
            .templer-unit{
                font-size: 14px;
                text-align: bottom;
                margin-top: 8px;
            }
        }
        .pm{
            text-align: center;
            color:  rgba(179, 181, 183, 1);
        }
        
    }
    .now-date{
        font-size: 32px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 28px;
        color: rgba(255, 255, 255, 1);
        .time{
            letter-spacing: 1.2px;
        }
        .date{
            font-size: 14px;
            display: flex;
            color:  rgba(179, 181, 183, 1);
        }
    }
}
</style>
