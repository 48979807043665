<template>
  <div class="box">
    <div
      class="right-border"
      :style="map == 'map' ? 'height: 48px;' : ''"
    ></div>
    <div
      class="title"
      :class="{ 'icon-style': map == 'map' }"
      :style="map == 'map' ? 'height: 47px;line-height: 14px;' : ''"
    >
      <div style="display: flex">
        <div>
          <img style="margin-top: 4px" :src="titleInfo.icon" alt="" />
        </div>
          <span v-for="(item, index) in titleInfo.title" :key="index" @click="handleClick(index)" 
          :class="canclickClass(index)"
          >
            {{ item }}
            <span v-if="index < titleInfo.title.length - 1"> | </span>
          </span>
      </div>
      <slot name="status"></slot>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

let props = defineProps({
  titleInfo: Object,
  map: String
});
const handleClick = (index) => {
  if (props.titleInfo.titleMethods && index < props.titleInfo.titleMethods.length) {
    props.titleInfo.titleMethods[index]();
  }
};
const canclickClass = (index) => {
  if (props.titleInfo && props.titleInfo.titleMethods && index < props.titleInfo.titleMethods.length && props.titleInfo.titleMethods[index] !== null) {
    return 'canclick';
  }
  return '';
};
let heightPx = props.map;
onMounted(() => {
  console.log(props.map);
});
</script>

<style scoped lang="scss">
.box {
  position: relative;
  .right-border {
    position: absolute;
    width: 100%;
    height: 36px;
    border-left: 0.5px solid rgba(35, 132, 222, 0.5);
    pointer-events: none;
  }
  .title {
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;
    width: 100%;
    height: 35px;
    border: 0.5px solid #ccc;
    border-image: linear-gradient(
        to right,
        rgba(35, 132, 222, 0.8) 0%,
        rgba(35, 132, 222, 0.4) 30%,
        rgba(35, 132, 222, 0.3) 50%,
        rgba(35, 132, 221, 0.1) 70%,
        rgba(35, 132, 221, 0) 100%
      )
      30 30;
    img {
      width: 18px;
      height: 18px;
      margin-left: 10px;
    }

    span {
      margin-left: 16px;
      margin-top: 3px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      // line-height: 20px;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: top;
      align-items: center;
    }
    span.canclick{
      cursor: pointer;
      
    }
    &.icon-style {
      align-items: center;
      img {
        width: 25.22px;
        height: 25.22px;
        margin-left: 14px;
      }
      span {
        font-size: 24px;
        line-height: 24px;
        margin-left: 24px;
      }
    }
  }
}
</style>




