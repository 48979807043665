<template>
  <div class="monitoring-box" v-show="isShowing == 'monitoring'">
    <div class="monitoring-header">
      <Header :updataTime="collectData.dataUpdataTime"></Header>
    </div>
    <div class="body-content" style="margin-top: 10px">
      <div class="body-left">
        <Statistics
          :collectData="collectData.taskMarket"
          direction="left"
        ></Statistics>
        <Marketing
          direction="left"
          :doing="collectData.taskMarket.doingList"
        ></Marketing>
        <HealthStatisc
          direction="left"
          :doing="collectData.taskMarket.doing"
        ></HealthStatisc>
        <PlanProject
          direction="left"
          :prepare="collectData.taskMarket.prepare"
        ></PlanProject>
      </div>
      <div class="body-center">
        <Map
          :mapData="collectData.mapData"
          :showDigitaltwinFun="showDigitaltwin"
        ></Map>
        <div class="center-bottom">
          <div class="center-bottom--left">
            <VideoConsult></VideoConsult>
          </div>
          <div class="center-bottom--right">
            <RealTime :recent="collectData.work.recent"></RealTime>
          </div>
        </div>
      </div>
      <div class="body-right">
        <Statistics
          :collectData="collectData.taskInner"
          direction="right"
        ></Statistics>
        <Marketing
          direction="right"
          :doing="collectData.taskInner.doingList"
        ></Marketing>
        <HealthStatisc
          direction="right"
          :doing="collectData.taskInner.doing"
        ></HealthStatisc>
        <PlanProject
          direction="right"
          :prepare="collectData.taskInner.prepare"
        ></PlanProject>
      </div>
    </div>
    <div
      v-if="!showRobot"
      class="robot-bottom"
      :class="{ hide: hideRobot }"
      @click="isShowRobot"
    >
      <img :src="srz" />
    </div>
    <div v-if="showRobot" class="robot-wrapper">
      <div class="robot-video">
        <video
          src="https://twzc-dev.oss-cn-shenzhen.aliyuncs.com/datav/oa_robot.webm#"
          autoplay
        ></video>
      </div>
      <div class="close" @click="isShowRobot">
        <svg
          t="1694750736370"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2600"
          width="32"
          height="32"
        >
          <path
            d="M563.8 512l262.5-312.9c4.4-5.2 0.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9c-4.4 5.2-0.7 13.1 6.1 13.1h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"
            p-id="2601"
            fill="#ffffff"
          ></path>
        </svg>
      </div>
    </div>
  </div>
  <div class="digitaltwin" v-show="isShowing == 'digitaltwin'">
    <div class="right-border"></div>
    <div class="title">
      <img :src="MapIcon" alt="" />
      <span @click="cancelShowDigitaltwin"> 利信中心 </span>
      <span @click="cancelShowDigitaltwin">
        <svg
          t="1697767727309"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4151"
          width="32"
          height="32"
        >
          <path
            d="M576 512l277.333333 277.333333-64 64-277.333333-277.333333L234.666667 853.333333 170.666667 789.333333l277.333333-277.333333L170.666667 234.666667 234.666667 170.666667l277.333333 277.333333L789.333333 170.666667 853.333333 234.666667 576 512z"
            fill="#ffffff"
            p-id="4152"
          ></path>
        </svg>
      </span>
    </div>
    <iframe  
      class="digitaltwin-content"
      src="http://demo.gdtwzc.cn/thing/share/rjhnwgx4u2nqze17"
      ></iframe>
    </div>
  </template>
  <!-- allow="camera;microphone" -->
  <!-- src="https://twzc019.twzc.zsvrsky.cn/ai2" -->

<script setup>
import Header from "./components/Header.vue";
import Statistics from "./components/Statistics.vue";
// import Title from './components/Title.vue';
import Marketing from "./components/Marketing.vue";
import HealthStatisc from "./components/HealthStatisc.vue";
import PlanProject from "./components/PlanProject.vue";
import Map from "./components/Map.vue";
import VideoConsult from "./components/VideoConsult.vue";
import RealTime from "./components/RealTime.vue";

import socket from "@/utils/websocket";
import service from "@/api/service";
import srz from "@/assets/images/szr.gif";
import MapIcon from "@/assets/images/city.png";
import { onMounted, reactive, onUnmounted, nextTick, ref, watch } from "vue";

import moment from "moment";
// console.log(ws);
const collectData = reactive({
  work: {
    title: "工程",
    icon: require("@/assets/images/marketing.png"),
    recent: {},
  },
  taskMarket: {
    title: "市场",
    icon: require("@/assets/images/marketing.png"),
    doing: {},
    doingList: {},
    prepare: {},
    count: {
      finish: 0,
      doing: 0,
      prepare: 0,
    },
  },
  taskInner: {
    title: "研发",
    icon: require("@/assets/images/research.png"),
    doing: {},
    doingList: {},
    prepare: {},
    count: {
      finish: 0,
      doing: 0,
      prepare: 0,
    },
  },
  mapData: {
    count: {
      sum: 0,
      doing: 0,
    },
    doing: {},
    points: {},
  },
});
const isShowing = ref("monitoring");
const hideRobot = ref(false);
const showRobot = ref(false);

const getMoment = () => {
  return moment();
};

onMounted(() => {
  init();
  setInterval(() => {
    console.error("更新数据");
    init();
  }, 5 * 60 * 1000);
  initSocket();

  // socket.init().then(res => {
  //     console.log('连接成功', res);
  //     console.log(getUpdataTime());
  //     socket.onsend(1111);
  // })
});
const initSocket = () => {
    socket.init().then((res) => {
      socket.ws.onmessage = (res) => {
        console.log("WebSocket收到信息 " + res.data);
        let data = JSON.parse(res.data);
        if (data.tag && data.tag == "send") {
          //接收到数据
          console.error("接收到发送者的数据" + data.data);
          if (data.data == "left") {
            //显示数字人
            hideRobot.value = false;
          } else if (data.data == "right") {
            //隐藏数字人
            showRobot.value = false;
            setTimeout(() => {
              hideRobot.value = true;
            }, 1000);
          }
        }
      };
    });
  };
  watch(socket.isWebSocketClose, (newVal, oldVal) => {
    if (newVal == false) {
      console.log("重连");
      setTimeout(()=>{
        initSocket();
      },2000)
    }
  });
const showDigitaltwin = () => {
  isShowing.value = "digitaltwin";
};
const cancelShowDigitaltwin = () => {
  isShowing.value = "monitoring";
};
const init = () => {
  setTimeout(function () {
    getWorkChartList();
    getTaskChartList();
    collectData.dataUpdataTime = getUpdataTime();
  }, 2000);
  setTimeout(function () {
    getMapChartList();
  }, 5000);
  // setTimeout(function(){
  //     generateRobot();
  // },10000)
};

// onUnmounted(() => {
//     socket.onclose();
// })

const isShowRobot = () => {
  showRobot.value = !showRobot.value;
};

/**
 * 生成数字人
 */
const generateRobot = () => {
  let myData = [
    collectData.taskMarket,
    collectData.taskInner,
    collectData.mapData.count,
  ];
  service.post("/dataV/generateRobot", { collectData: myData }).then((res) => {
    console.log("生成数字人成功", res);
  });
};

const getData = () => {
  service.post("/api/data.DataV/project_progress").then((res) => {
    console.log(res);
  });
};

// 获取近3天工程列表
const getWorkChartList = () => {
  service.post("/datav/getChartData", { type: "work" }).then((res) => {
    console.log("工程部内容", res.data);
    collectData.work.recent = res.data;
  });
};
// 获取项目列表--研发
const getTaskChartList = () => {
  service.post("/datav/getChartData", { type: "task" }).then((res) => {
    console.log("研发内容", res.data);
    collectData.taskMarket.count = res.data.market.count;
    let markDoingList = [];
    let myNo = 0;
    Object.keys(res.data.market.doing).map((key) => {
      // res.data.market.doing[key].health = 'normal'
      res.data.market.doing[key].no = myNo;
      myNo++;
      markDoingList.push(res.data.market.doing[key]);
    });
    collectData.taskMarket.doingList = markDoingList;
    collectData.taskMarket.doing = res.data.market.doing;
    collectData.taskMarket.prepare = res.data.market.prepare;

    collectData.taskInner.count = res.data.inner.count;
    let innerDoingList = [];
    myNo = 0;
    Object.keys(res.data.inner.doing).map((key) => {
      // res.data.inner.doing[key].health = 'normal'
      res.data.inner.doing[key].no = myNo;
      myNo++;
      innerDoingList.push(res.data.inner.doing[key]);
    });
    collectData.taskInner.doingList = innerDoingList;
    collectData.taskInner.doing = res.data.inner.doing;
    collectData.taskInner.prepare = res.data.inner.prepare;
  });
};
const getMapChartList = () => {
  service.post("/datav/getChartData", { type: "map" }).then((res) => {
    console.log("地图标记内容", res.data);
    collectData.mapData.count = res.data.count;
    collectData.mapData.doing = res.data.doing;
    collectData.mapData.points = res.data.points;
  });
};
const getUpdataTime = () => {
  return getMoment().format("YYYY-MM-DD HH:mm:ss");
};
</script>

<style scoped lang="scss">
.robot-bottom {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  transition: all 1s;
  // background: url("../../assets/images/szr.gif");
  img {
    width: auto;
    height: 14vh;
    cursor: pointer;
    // height: 100%;
  }
  &.hide {
    transform: translateX(100%);
  }
}
.robot-wrapper {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  width: 300px;
  height: 300px;
  .robot-video {
    width: 100%;
    height: 100%;
  }
  &.hidden {
    display: none;
  }
  .close {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    cursor: pointer;
  }
  video {
    width: 100%;
    height: 100%;
  }
}
.monitoring-box {
  position: relative;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  background-color: rgba(0, 2, 10, 0.9);
  background-image: radial-gradient(
    51.98% 50%,
    rgba(1, 52, 74, 0.5) 0%,
    rgba(0, 1, 28, 0.5) 100%
  );

  .monitoring-header {
    width: 100%;
    height: 90px;
    padding: 0px 21px;
    background: url("../../assets/images/header_bottom.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .header-bottom--color {
    height: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .color {
      width: 517.73px;
      height: 5px;
      opacity: 1;
      background: linear-gradient(
        135deg,
        rgba(35, 132, 221, 0) 0%,
        rgba(103, 182, 255, 1) 48.96%,
        rgba(35, 132, 221, 0) 100%
      );
      filter: blur(6.94px);
    }
  }

  .body-content {
    width: calc(100% - 48px);
    height: calc(100% - 100px);
    margin: 0 auto;
    display: flex;

    .body-left {
      width: 482px;
      // .title{
      //     margin: 10px 0;
      // }
    }

    .body-center {
      width: calc(100% - 48px - (482px * 2));
      margin-left: 24px;

      .center-bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;

        & > .center-bottom--left {
          width: 257px;
        }

        & > .center-bottom--right {
          width: calc(100% - 257px - 36px);
        }
      }
    }

    .body-right {
      width: 482px;
      margin-left: 24px;
    }
  }
}
.digitaltwin {
  width: 1920px;
  height: 1080px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 0.3px solid rgba(44, 179, 255, 0.2);
  background-color: rgba(0, 2, 10, 0.9);
  background-image: radial-gradient(
    51.98% 50%,
    rgba(1, 52, 74, 0.5) 0%,
    rgba(0, 1, 28, 0.5) 100%
  );
  position: relative;
  box-sizing: border-box;
  .right-border {
    position: absolute;
    width: 100%;
    height: 36px;
    border-left: 0.5px solid rgba(35, 132, 222, 0.5);
    pointer-events: none;
  }
  .title {
    margin: 0;
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;
    width: 100%;
    height: 35px;
    border: 0.5px solid #ccc;
    border-image: linear-gradient(
        to right,
        rgba(35, 132, 222, 0.8) 0%,
        rgba(35, 132, 222, 0.4) 30%,
        rgba(35, 132, 222, 0.3) 50%,
        rgba(35, 132, 221, 0.1) 70%,
        rgba(35, 132, 221, 0) 100%
      )
      30 30;
    img {
      width: auto;
      height: 80%;
      margin-left: 10px;
    }

    span {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1px;
      // line-height: 20px;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: top;
      align-items: center;
      cursor: pointer;
    }
    &.icon-style {
      align-items: center;
      img {
        width: 25.22px;
        height: 25.22px;
        margin-left: 14px;
      }
      span {
        font-size: 24px;
        line-height: 24px;
        margin-left: 24px;
      }
    }
  }
  .digitaltwin-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
