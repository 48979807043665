<template>
    <div class="title">
        <Title :titleInfo="headerData.titleInfo"></Title>
    </div>
    <div class="video-consult">
        <div class="video-item">
            <div class="video-video">
                <video
                    height="100%"
                    muted
                    autoplay
                    controls
                    :src="video1_src"
                    ref="myVideo1"
                ></video>
            </div>

            <!-- <div @click="handlePlay" v-if="dataVideo.isShowTop" class="video-shade"></div> -->
        </div>
        <div class="video-item">
            <div class="video-video">
                <video
                    height="100%"
                    muted
                    autoplay
                    controls
                    :src="video2_src"
                    ref="myVideo2"
                ></video>
            </div>
            <!-- @/assets/video/video.mp4 -->
            <!-- <div @click="handlePlayBtm" v-if="dataVideo.isShowBottom" class="video-shade"></div> -->
        </div>
    </div>
</template>

<script setup>
import Title from './Title.vue';
import {nextTick, onMounted, reactive, ref,} from 'vue';

const headerData = reactive({
    titleInfo: {
        icon: require("@/assets/images/video_1.png"),
        title: ['视频资讯']
    }
})
let myVideo1 = ref();
let myVideo2 = ref();
const video1_src = ref("http://172.16.10.70/wvp/rtp/34020000001110000015_34020000001110000015.live.mp4");
// const video1_src = ref("https://expo.zsvrsky.cn/camera/rtp/34020000001110000015_34020000001110000015.live.mp4");
const video2_src = ref("http://172.16.10.70/wvp/rtp/34020000001110000009_34020000001110000009.live.mp4");
// const video2_src = ref("https://expo.zsvrsky.cn/camera/rtp/34020000001110000009_34020000001110000009.live.mp4");
const dataVideo = reactive({
    controlsTop: false,
    controlsBtm: false,
    isShowTop: true,
    isShowBottom: true,
})

const handlePlay = () => {
    dataVideo.controlsTop = true;
    dataVideo.isShowTop = false;
    myVideo1.value.play();
}

const handlePlayBtm = () => {
    dataVideo.controlsBtm = true;
    dataVideo.isShowBottom = false;
    myVideo2.value.play();
}
var myInterval
onMounted(()=>{
    myInterval = setInterval(()=>{
        myVideo1.value.pause();
        myVideo2.value.pause();
        let temp1 = video1_src.value;
        let temp2 = video2_src.value;
        video1_src.value = "#";
        video2_src.value = "#";
        setTimeout(()=>{
            video1_src.value = temp1;
            video2_src.value = temp2;
        },1000)
        nextTick(()=>{
            myVideo1.value.play();
            myVideo2.value.play();
        })
    },5*60*1000)
})
// myVideo1.addEventListener('onclick', () => {
//     console.log('111111');
// })
</script>

<style scoped lang="scss">
.title {
    margin-top: 16px;
}

.video-consult {
    width: 100%;
    height: 322px;

    .video-item {
        width: 100%;
        height: calc((100% - 32px) / 2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 12px;
        background: url("~@/assets/images/video.png");
        background-size: 100% 100%;
        position: relative;

        .video-video {
            width: 96%;
            height: 90%;
            margin: auto auto;

            video {
                width: 100%;
                height: 100%;
            }

            // background: transparent url('img/1.jpg') 50% 50% no-repeat;
        }

        .video-shade {
            position: absolute;
            width: 98%;
            height: 90%;
            margin: auto auto;
            background: url('../../../assets/video/video.png');
            background-size: 100% 100%;
            cursor: pointer;
            top: 7px;
        }
    }
}
</style>
