<template>
  <div class="title">
    <Title :titleInfo="headerData.titleInfo"></Title>
  </div>
  <div v-if="hasData" class="marketing-content">
    <div class="item-wrapper" @mouseenter="mouseMove" @mouseleave="mouseleave">
      <div class="items" ref="itemsRef">
        <div
          class="content-item"
          v-for="item in doingList"
          :key="item.no"
          :ref="setRef"
        >
          <div class="base-info">
            <div class="sort">
              <div>
                {{ item.no + 1 < 10 ? "0" + (item.no + 1) : item.no + 1 }}
              </div>
              <span>{{ item.name }}</span>
            </div>
            <div class="principal">
              <span>负责人：</span>
              <span>{{ item.personName }}</span>
            </div>
          </div>
          <div class="progress-info">
            <div class="process_wrapper">
              <div class="projectRatio">
                <span class="process_name">项目进度</span>
                <div class="progress">
                  <el-progress
                    :class="item.health"
                    :percentage="item.process"
                    :color="getProgressColor(item)"
                    :define-back-color="defineBackColor"
                  >
                    <template #default="{ percentage }">
                      <span
                        style="color: rgba(0, 222, 255, 1); font-weight: 700"
                        >{{ percentage }}%</span
                      >
                    </template>
                  </el-progress>
                </div>
              </div>
              <div class="timeRatio">
                <span class="process_name">时间进度</span>
                <div class="progress">
                  <el-progress
                    :percentage="item.timeRatio"
                    :color="progressNormalColors"
                    :define-back-color="defineBackColor"
                  >
                    <template #default="{ percentage }">
                      <span
                        style="color: rgba(0, 222, 255, 1); font-weight: 700"
                        >{{ percentage }}%</span
                      >
                    </template>
                  </el-progress>
                </div>
              </div>
            </div>
            <!-- 暂时修改为正常执行 -->
            <div class="operation-status">
              <div class="status-style"></div>
              <!-- <div class="status-style" :class="item.health"></div> -->
              <span >正常执行</span>
              <!-- <span :class="item.health">{{ healhArray[item.health] }}</span> -->
            </div>
          </div>
          <div class="date-info">
            <div class="now-status">
              <span>目前进度：</span>
              <span>{{ item.doing == null ? "-" : item.doing.name }}</span>
            </div>
            <div class="make_date">
              <span>起止时间：</span>
              <span
                >{{ item.doing == null ? "" : item.doing.start_time }}-{{
                  item.doing == null ? "" : item.doing.end_time
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="loading marketing-content">正在加载中</div>
</template>

<script setup>
import { type } from "os";
import Title from "./Title.vue";
import {
  reactive,
  computed,
  ref,
  onMounted,
  watch,
  watchEffect,
  onUnmounted,
} from "vue";
import { nextTick } from "process";
// import { watch } from "fs";
const healhArray = {
  normal: "正常执行",
  warning: "告警",
  error: "异常",
};
const itemsRef = ref(null);
const headerData = reactive({
  titleInfo: {
    icon: require("@/assets/images/marketing_project.png"),
    title: ["正在进行的项目"],
  },
});
const props = defineProps({
  direction: String,
  doing: Array,
});
const doingList = ref([]);
watch(
  () => props.doing,
  (newVal) => {
    //暂停动作和恢复原点
    cancelAnimationFrame(timer.value);
    clearTimeout(myTimeout.value);
    itemsRef.value.scrollTop = 0;
    // 更新doingList的值
      doingList.value = newVal;
      nextTick(()=>{
        start();
      })
    // nextTick(() => {
    //   myInterval = setInterval(() => {
    //     if (doingList.value.length > 0) {
    //       // 取出第一个元素
    //       const first = doingList.value.shift();
    //       myTimeout = setTimeout(() => {
    //         // 将第一个元素插入到队尾
    //         doingList.value.push(first);
    //       }, 1000);
    //     }
    //   }, 4000);
    // });
  }
);
// watchEffect(() => {
//   let data = ref(props.doing);
//   if (data.value.length <= 0) {
//     console.error("没变化");
//     doingList.value = [];
//   } else {
//     clearInterval(myInterval);
//     setTimeout(() => {
//       doingList.value = props.doing;
//       myInterval = setInterval(() => {
//         if (doingList.value.length > 0) {
//           // 取出第一个元素
//           const first = doingList.value.shift();
//           setTimeout(() => {
//             // 将第一个元素插入到队尾
//             doingList.value.push(first);
//           }, 800);
//         }
//       }, 5000);
//     }, 200);
//   }
// });
const defineBackColor = "#80888e";
const progressNormalColors = "#00deff";
const progressWarningColors = "#f4e522";
const progressErrorColors = "#ea3c3c";
function getProgressColor(item) {
  if (item.health == "wraning") {
    return progressWarningColors;
  } else if (item.health == "error") {
    return progressErrorColors;
  } else {
    return progressNormalColors;
  }
}
const timer = ref(null);
const myTimeout = ref(null);
var hasData = 1;
// // 判断数据是否存在
// const hasData = computed(() => {
//   return Object.keys(props.doing.value || {}).length > 0;
// });
const eleRefs = ref([]);
// const itemHeight = ref(0);
const setRef = (el) => {
  if (el) {
    eleRefs.value.push(el);
  }
};
onMounted(() => {
  // 定义定时任务
  // myInterval = setInterval(() => {
  //   if (doingList.value.length > 0) {
  //     // 取出第一个元素
  //     const first = doingList.value.shift();
  //     myTimeout = setTimeout(() => {
  //       // 将第一个元素插入到队尾
  //       doingList.value.push(first);
  //     }, 1000);
  //   }
  // }, 4000);

  nextTick(() => {
    //获取变量值
    setTimeout(() => {
      // itemHeight.value = eleRefs.value[0].offsetHeight;
      start();
    }, 1000);
  });
});
/**
 * @Description: 鼠标移动事件
 * @Author: admin
 */
function mouseMove() {
  cancelAnimationFrame(timer.value);
  clearTimeout(myTimeout.value);
}
/**
 * @Description: 鼠标离开事件
 * @Author: admin
 */
function mouseleave() {
  start();
}
//等同于vue2中的destroyed
onUnmounted(() => {
  //清除定时器
  cancelAnimationFrame(timer.value);
  clearTimeout(myTimeout.value);
});
//开启定时器方法
function start() {
  //清除定时器
  cancelAnimationFrame(timer.value);
  clearTimeout(myTimeout.value);
  //定时器触发周期
  MarqueeTest();
  // timer.value = setInterval(MarqueeTest, speed.value);
}
function MarqueeTest() {
  let test1 = itemsRef.value;
  let height = test1.getElementsByClassName("content-item")[0].offsetHeight +6;
  //判断组件是否渲染完成
  if (test1.getElementsByClassName("content-item").length == 0) {
    test1 = itemsRef.value;
  } else {
    //如果列表数量过少不进行滚动
    if (test1.getElementsByClassName("content-item").length <= 3) {
      cancelAnimationFrame(timer.value);
      clearTimeout(myTimeout.value);
      return;
    }
    // test1.style.tranform = "translateY(-" + height + "px)"
    // console.error("test1.style.tranform",test1.style.tranform);
    //组件进行滚动
    test1.scrollTop += 1;
    // console.error("test1.scrollTop", test1.scrollTop);
    
    if(test1.scrollTop >= height){
      //滚了一个了，就节点更换
      //获取组件第一个节点
      let a = test1.childNodes[0];
      //删除节点
      test1.removeChild(a);
      //将该节点拼接到组件最后
      test1.append(a);
      myTimeout.value = setTimeout(()=>{
        timer.value = requestAnimationFrame(MarqueeTest);
      },5000)
        test1.scrollTop = 0;
    }else{
      test1.scrollTop += 1;
      timer.value = requestAnimationFrame(MarqueeTest);
    }

    // //判断滚动条是否滚动到底部
    // if (test1.scrollTop == test1.scrollHeight - test1.clientHeight) {
    //   //获取组件第一个节点
    //   let a = test1.childNodes[0];
    //   //删除节点
    //   test1.removeChild(a);
    //   //将该节点拼接到组件最后
    //   test1.append(a);
    // }
    // myTimeout.value = setTimeout(()=>{
    //   timer.value = requestAnimationFrame(MarqueeTest);
    // },50)
  }
}
</script>

<style lang="scss" >
.marketing-content .el-progress-bar__outer {
  background: rgba(128, 136, 142, 0.4) !important;
}
.marketing-content .fade-enter-active,
.marketing-content .fade-leave-active {
  transition: all 0.8s;
}
.marketing-content .fade-enter,
.marketing-content .fade-leave-to {
  opacity: 0;
  // height: 0;
  transform: translate(20px, 0);
}

.el-progress-bar__inner {
  background-color: unset;
  background-image: linear-gradient(
    270deg,
    rgba(77, 255, 223, 1) 0%,
    rgba(77, 161, 255, 1) 100%
  ) !important;
}

.el-progress.warning .el-progress-bar__inner {
  background-color: unset;
  background-image: linear-gradient(
    90deg,
    rgba(63, 236, 255, 0.8) 0%,
    rgba(0, 222, 255, 1) 100%
  ) !important;
}
.el-progress.error .el-progress-bar__inner {
  background-color: unset;
  background-image: linear-gradient(
    90deg,
    rgba(255, 83, 83, 0.8) 0%,
    rgb(255, 0, 0) 100%
  ) !important;
}
.title {
  margin-top: 16px;
}

.marketing-content {
  width: 100%;
  height: 323px;
  background: rgba(35, 133, 223, 0.15);
  margin-top: 10px;
  padding: 16px 24px;
  box-sizing: border-box;
  overflow-y: hidden;
  scrollbar-width: none; /* 隐藏垂直滚动条 */
  border: 1px solid rgba(35, 132, 222, 0.5);

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  .item-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none; /* 隐藏垂直滚动条 */
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }
  }
  .items {
    height: 100%;
    overflow: scroll;
    transition: all 0.6s;
    transform: translateZ(0);
    scrollbar-width: none; /* 隐藏垂直滚动条 */

    // transform: translateY();
    &::-webkit-scrollbar {
      display: none;
    }
  }
  // scrolll-y: auto;
  .content-item {
    // &:hover {
    //   cursor: pointer;

    //   .date-info {
    //     background: rgba(44, 179, 255, 0.2);
    //     border: 1px solid rgba(44, 179, 255, 1);
    //   }
    // }
    width: 100%;
    height: calc((100% - 18px) / 3);
    margin-bottom: 6px;
    // margin-top: 10x;
    .base-info,
    .progress-info,
    .date-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .base-info {
      .sort {
        display: flex;

        div {
          width: 18px;
          height: 20px;
          border: 1px solid #fff;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 20px;
          color: rgba(255, 255, 255, 1);
          text-align: center;
          vertical-align: top;
        }

        span {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.6);
          margin-top: 2px;
          margin-left: 12px;
        }
      }

      .principal {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 22px;
        color: rgba(255, 255, 255, 1);
        text-align: right;
        vertical-align: top;
      }
    }

    .progress-info {
      width: 100%;
      margin-top: 14px;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      .process_wrapper {
        display: flex;
        flex-direction: column;
        flex: 0 1 80%;

        .projectRatio,
        .timeRatio {
          width: 100;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          .process_name {
            flex: 0 1 60px;
            color: #fff;
            font-size: 12px;
            margin-right: 10px;
          }

          .progress {
            display: inline-block;
            width: 100%;
          }
        }
      }

      // width: calc();

      .operation-status {
        width: 200px;
        flex: 0 1 60px;
        display: flex;
        flex-direction: row;
        width: 90px;
        align-items: center;
        justify-content: flex-end;

        span {
          margin-left: 4px;
          color: rgba(0, 222, 255, 1);
          font-size: 12px;

          &.warning {
            color: rgba(244, 229, 34, 1);
          }

          &.error {
            color: rgba(234, 60, 60, 1);
          }
        }

        .status-style {
          width: 7px;
          height: 7px;
          background: rgba(0, 222, 255, 1);

          &.warning {
            background: rgba(244, 229, 34, 1);
          }

          &.error {
            background: rgba(234, 60, 60, 1);
          }
        }
      }
    }

    .date-info {
      // padding: 2px 4px;
      margin-top: 8px;
      height: 21px;
      // background: rgba(44, 179, 255, 0.1);
      background: rgba(44, 179, 255, 0.1);
      font-size: 12px;
      // font-weight: 700;
      letter-spacing: 0px;
      // line-height: 21px;
      // align-items: center;
      color: rgba(255, 255, 255, 1);
      text-align: right;
      vertical-align: top;

      .make_date {
        margin-right: 6px;
      }

      .now-status {
        margin-left: 6px;
      }
    }
  }
}
</style>
