<template>
  <div class="title">
    <Title :titleInfo="headerData.titleInfo"></Title>
  </div>
  <div v-if="hasData" class="plan-content">
    <div class="items" ref="itemsRef" @mouseenter="mouseMove" @mouseleave="mouseleave">
      <!-- <vue3SeamlessScroll></vue3SeamlessScroll>   -->
      <div
        class="content-item"
        v-for="(item, index) in prepareList"
        :key="item.id"
      >
        <div class="base-info">
          <div class="sort">
            <div :class="'sort' + (index + 1)">
              {{ index + 1 < 10 ? "0" + (index + 1) : index + 1 }}
            </div>
            <span>{{ item.name }}</span>
          </div>
          <div class="principal">
            <span>负责人：</span>
            <span>{{ item.personName }}</span>
          </div>
        </div>
        <div class="date-info">
          <div class="now-status">
            <span>预计开始时间</span>
          </div>
          <div class="make_date">
            <span>{{ item.start_time }}</span>
          </div>
        </div>
        <div class="date-info">
          <div class="now-status">
            <span>截止时间</span>
          </div>
          <div class="make_date">
            <span>{{ item.end_time }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="plan-content loading">正在加载中</div>
</template>

<script setup>
import { has } from "lodash";
import Title from "./Title.vue";
import { reactive, computed, onUnmounted, onMounted, nextTick,ref,watch } from "vue";
const headerData = reactive({
  titleInfo: {
    icon: require("@/assets/images/plat_project.png"),
    title: ["计划项目"],
  },
});

let props = defineProps({
  direction: String, // 当前组件所在位置判断值
  prepare: Object,
});
const itemsRef = ref(null);
//requestAnimationFrame(MarqueeTest)的返回值
const timer = ref(null);
//setTimeout的返回值
const myTimeout = ref(null);
const prepareList = computed(() => {
  const { prepare } = props;
  if (!prepare) {
    return {};
  }
  const newData = Object.keys(prepare).map((key) => {
    let { id, name, personName, start_time, end_time } = prepare[key];
    return { id, name, personName, start_time, end_time };
  });
  return newData;
});
watch(prepareList,
  (newVal) => {
    //暂停动作和恢复原点
    cancelAnimationFrame(timer.value);
    clearTimeout(myTimeout.value);
    nextTick(()=>{
    itemsRef.value.scrollTop = 0;
      start();
    })
  }
);
// 判断数据是否存在
const hasData = computed(() => {
  // console.error("prepareList", prepareList.value);
  // console.error(
  //     "Object.keys(prepareList.value || {})",
  //     Object.keys(prepareList.value || {})
  // );
  return Object.keys(prepareList.value || {}).length > 0;
});
/**
 * @Description: 鼠标移动事件
 * @Author: admin
 */
function mouseMove() {
  cancelAnimationFrame(timer.value);
  clearTimeout(myTimeout.value);
}
/**
 * @Description: 鼠标离开事件
 * @Author: admin
 */
function mouseleave() {
  start();
}
onMounted(() => {
  nextTick(() => {
    setTimeout(() => {
      start();
    }, 1000);
  });
});
//等同于vue2中的destroyed
onUnmounted(() => {
  //清除定时器
  cancelAnimationFrame(timer.value);
  clearTimeout(myTimeout.value);
});
//开启定时器方法
function start() {
  //清除定时器
  cancelAnimationFrame(timer.value);
  clearTimeout(myTimeout.value);
  //定时器触发周期
  MarqueeTest();
  // timer.value = setInterval(MarqueeTest, speed.value);
}
function MarqueeTest() {
  let test1 = itemsRef.value;
  //判断组件是否渲染完成
  if (test1.getElementsByClassName("content-item").length == 0) {
    test1 = itemsRef.value;
  } else {
    //如果列表数量过少不进行滚动
    if (test1.getElementsByClassName("content-item").length <= 2) {
      cancelAnimationFrame(timer.value);
      clearTimeout(myTimeout.value);
      return;
    }
    //组件进行滚动
    test1.scrollTop += 1;
     // 判断滚动条是否滚动到底部
    if (test1.scrollTop == test1.scrollHeight - test1.clientHeight) {
      //获取组件第一个节点
      let a = test1.childNodes[0];
      //删除节点
      test1.removeChild(a);
      //将该节点拼接到组件最后
      test1.append(a);
    }
    myTimeout.value = setTimeout(() => {
      timer.value = requestAnimationFrame(MarqueeTest);
    }, 100);
  }
}
</script>

<style scoped lang="scss">
.title {
  margin-top: 16px;
}

.plan-content {
  width: 100%;
  height: 200px;
  background: rgba(35, 133, 223, 0.15);
  margin-top: 10px;
  padding: 16px 24px;
  box-sizing: border-box;
  overflow-y: scroll;
  border: 1px solid rgba(35, 132, 222, 0.5);
  scrollbar-width: none; /* 隐藏垂直滚动条 */

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  .items{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    transition: all 0.6s;
    transform: translateZ(0);
    scrollbar-width: none; /* 隐藏垂直滚动条 */

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }
  }
  // scrolll-y: auto;
  .content-item {
    width: 100%;
    height: calc((100% - (12px * 2)) / 2);
    // margin-top: 10px;
    margin-bottom: 6px;

    .base-info,
    .date-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .base-info {
      margin-bottom: 6px;

      .sort {
        display: flex;

        div {
          width: 18px;
          height: 20px;
          border: 1px solid rgba(255, 255, 255, 0.6);
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.6);
          text-align: center;
          vertical-align: top;
        }

        & > .sort1 {
          border: 1px solid rgba(234, 60, 60, 1);
          color: rgba(234, 60, 60, 1);
        }

        & > .sort2 {
          border: 1px solid rgba(255, 125, 38, 1);
          color: rgba(255, 125, 38, 1);
        }

        & > .sort3 {
          border: 1px solid rgba(159, 252, 254, 1);
          color: rgba(159, 252, 254, 1);
        }

        span {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.6);
          margin-top: 2px;
          margin-left: 12px;
        }
      }

      .principal {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 22px;
        color: rgba(255, 255, 255, 1);
        text-align: right;
        vertical-align: top;
      }
    }

    .date-info {
      margin-top: 4px;

      &:nth-child(1) {
        margin-top: 10px;
      }

      // padding: 2px 4px;

      height: 21px;
      // background: rgba(44, 179, 255, 0.1);
      background: rgba(44, 179, 255, 0.1);
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0px;
      // line-height: 21px;
      // align-items: center;
      color: rgba(255, 255, 255, 1);
      text-align: right;
      vertical-align: top;

      .make_date {
        margin-right: 6px;
      }

      .now-status {
        margin-left: 6px;
      }
    }
  }
}
</style>
