<template>
  <!-- <transition name="custom-teleport"> -->
    <!-- <Teleport to="body" :disabled="isShowing == 'map'"> -->
      <div
        class="map_wrapper"
        >
        <!-- :class="{ showDigitaltwin: isShowing == 'digitaltwin' }" -->
        <div class="title">
          <Title map="map" :titleInfo="headerData.titleInfo">
            <!-- <template #status> -->
            <!-- <div class="status-type"> -->
            <!-- <div class="status status-error">
              <div></div>
              <span>异常</span>
            </div>
            <div class="status status-success">
              <div></div>
              <span>正常</span>
            </div> -->
            <!-- </div> -->
            <!-- </template> -->
          </Title>
        </div>
        <!-- <div class="map" v-show="isShowing == 'map'"> -->
        <div class="map">
          <div class="map-map" id="info"></div>
          <div class="operations">
            <el-checkbox
              v-model="checkObj.pingan"
              @change="handleSelectChange(1)"
            >
              <img
                class="check-icon"
                src="@/assets/images/icon_pingan_white.png"
                alt=""
              />
              <span style="color: #fff">平安中山</span>
            </el-checkbox>
            <el-checkbox
              v-model="checkObj.jiaotong"
              @change="handleSelectChange(2)"
            >
              <img
                class="check-icon"
                src="@/assets/images/icon_jiaotong_white.png"
                alt=""
              />
              <span style="color: #fff">交通局</span>
            </el-checkbox>
            <el-checkbox
              v-model="checkObj.huanbao"
              @change="handleSelectChange(3)"
            >
              <img
                class="check-icon"
                src="@/assets/images/icon_huanbao_white.png"
                alt=""
              />
              <span style="color: #fff">环保</span>
            </el-checkbox>
            <el-checkbox
              v-model="checkObj.gonglu"
              @change="handleSelectChange(4)"
            >
              <img
                class="check-icon"
                src="@/assets/images/icon_gonglu_white.png"
                alt=""
              />
              <span style="color: #fff">公路局</span>
            </el-checkbox>
          </div>
          <div class="statisc">
            <div class="static-box">
              <div class="static-box--item">
                <div>工程点数</div>
                <div>{{ props.mapData.count.sum }}</div>
              </div>
              <div class="slicer"></div>
              <div class="static-box--item">
                <div>运行正常</div>
                <div>
                  {{ props.mapData.count.sum - props.mapData.count.doing }}
                </div>
              </div>
              <div class="slicer"></div>
              <div class="static-box--item">
                <div>异常点数</div>
                <div>{{ props.mapData.count.doing }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="digitaltwin" v-show="isShowing == 'digitaltwin'">
          <iframe
            class="digitaltwin-content"
            src="http://demo.gdtwzc.cn/thing/share/rjhnwgx4u2nqze17"
          ></iframe>
        </div> -->
      </div>
    <!-- </Teleport> -->
  <!-- </transition> -->
</template>

<script setup>
import Title from "./Title.vue";
import markSuccess from "@/assets/images/mark_success.png";
import {
  onMounted,
  reactive,
  toRefs,
  ref,
  computed,
  watch,
  toRaw,
  nextTick,
} from "vue";
const headerData = reactive({
  titleInfo: {
    icon: require("@/assets/images/city.png"),
    title: ["全市一览", "利信中心"],
    // title: ["全市一览"],
    titleMethods: [null, triggerFunc],
  },
});

let map;
// 循环创建信息窗体
var infoWindows = [];
// 创建标记点击弹出的自定义窗体
var infoWindowByClick = null;
const isShowing = ref("map");
// 创建自定义窗体实例
// infoWindowInstance.value = createInfoWindowInstance(infoWindowComponent)
const checkObj = ref({
  pingan: true,
  jiaotong: true,
  gonglu: true,
  huanbao: true,
});
const props = defineProps({
  mapData:Object,
  showDigitaltwinFun:Function
});

//计算标记列表
const computedMarkers = computed(() => {
  if (!props.mapData) {
    return [];
  }
  // 进行一些数据处理操作
  const newData = [];
  for (let index = 0; index < props.mapData.points.length; index++) {
    //生成marker必要的信息
    let element = props.mapData.points[index];
    let item = {
      id: element.id,
      position: new window.TMap.LatLng(element.latitude, element.longitude),
    };
    if (element.category == 1) {
      item.styleId = "pingan";
    } else if (element.category == 2) {
      item.styleId = "jiaotong";
    } else if (element.category == 3) {
      item.styleId = "huanbao";
    } else {
      item.styleId = "gonglu";
    }
    if (element.style == "red") {
      item.styleId = item.styleId + "_red";
    }
    //生成点击marker时显示自定义信息窗体的内容
    item.myContent = generateInfoWindowContent(element, element.style);
    newData.push(item);
  }
  return newData;
});
// const computedMarkersClusters = computed(() => {
//   if (!props.mapData) {
//     return [];
//   }
//   // 进行一些数据处理操作
//   const newData = [];
//   for (let index = 0; index < props.mapData.points.length; index++) {
//     let element = props.mapData.points[index];
//     let item = {
//       position: new window.TMap.LatLng(element.latitude, element.longitude),
//     };
//     newData.push(item);
//   }
//   return newData;
// });
// 存放标点和文本标记
var mapMarker = null;
var markerCluster = null;
var mapLabel = null;
var oldZoomed = 12.6;
var labelStyle = null;
onMounted(() => {
  // if (window.onload) {
  //   initMap();
  // } else {
  //   window.addEventListener("load", () => {
  //     initMap();
  //   });
  // }
  initMap();
  console.log("初始化地图window", window);
});

function showMap() {
  return;
}

function triggerFunc() {
  props.showDigitaltwinFun();
}

function initMap() {
  labelStyle = {
    jiaotong: new window.TMap.MarkerStyle({
      // 交通
      width: 15, // 点标记样式宽度（像素）
      height: 17, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_jiaotong.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      // "anchor": { x: 16, y: 32 }
    }),
    huanbao: new window.TMap.MarkerStyle({
      // 环保
      width: 15, // 点标记样式宽度（像素）
      height: 17, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_huanbao.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      // "anchor": { x: 16, y: 32 }
    }),
    pingan: new window.TMap.MarkerStyle({
      // 安保
      width: 15, // 点标记样式宽度（像素）
      height: 17, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_pingan.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      // "anchor": { x: 16, y: 32 }
    }),
    gonglu: new window.TMap.MarkerStyle({
      // 环保
      width: 15, // 点标记样式宽度（像素）
      height: 17, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_gonglu.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      // "anchor": { x: 16, y: 32 }
    }),
    //2是更大的版本
    jiaotong2: new window.TMap.MarkerStyle({
      width: 18, // 点标记样式宽度（像素）
      height: 21, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_jiaotong.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      anchor: { x: 5, y: 80 },
    }),
    huanbao2: new window.TMap.MarkerStyle({
      width: 18, // 点标记样式宽度（像素）
      height: 21, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_huanbao.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      anchor: { x: 5, y: 80 },
    }),
    pingan2: new window.TMap.MarkerStyle({
      width: 18, // 点标记样式宽度（像素）
      height: 21, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_pingan.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      anchor: { x: 5, y: 80 },
    }),
    gonglu2: new window.TMap.MarkerStyle({
      width: 18, // 点标记样式宽度（像素）
      height: 21, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_gonglu.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      anchor: { x: 5, y: 80 },
    }),
    error_mark: new window.TMap.MarkerStyle({
      width: 300, // 点标记样式宽度（像素）
      height: 120, // 点标记样式高度（像素）
      src: require("@/assets/images/mark_error.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      anchor: { x: 30, y: 130 },
    }),
    success_mark: new window.TMap.MarkerStyle({
      width: 300, // 点标记样式宽度（像素）
      height: 120, // 点标记样式高度（像素）
      src: require("@/assets/images/mark_success.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      anchor: { x: 30, y: 130 },
    }),
    jiaotong_red: new window.TMap.MarkerStyle({
      // 交通
      width: 15, // 点标记样式宽度（像素）
      height: 17, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_jiaotong_red.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      // "anchor": { x: 16, y: 32 }
    }),
    huanbao_red: new window.TMap.MarkerStyle({
      // 环保
      width: 15, // 点标记样式宽度（像素）
      height: 17, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_huanbao_red.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      // "anchor": { x: 16, y: 32 }
    }),
    pingan_red: new window.TMap.MarkerStyle({
      // 安保
      width: 15, // 点标记样式宽度（像素）
      height: 17, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_pingan_red.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      // "anchor": { x: 16, y: 32 }
    }),
    gonglu_red: new window.TMap.MarkerStyle({
      // 环保
      width: 15, // 点标记样式宽度（像素）
      height: 17, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_gonglu_red.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      // "anchor": { x: 16, y: 32 }
    }),
    //2是更大的版本
    jiaotong2_red: new window.TMap.MarkerStyle({
      width: 18, // 点标记样式宽度（像素）
      height: 21, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_jiaotong_red.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      anchor: { x: 5, y: 80 },
    }),
    huanbao2_red: new window.TMap.MarkerStyle({
      width: 18, // 点标记样式宽度（像素）
      height: 21, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_huanbao_red.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      anchor: { x: 5, y: 80 },
    }),
    pingan2_red: new window.TMap.MarkerStyle({
      width: 18, // 点标记样式宽度（像素）
      height: 21, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_pingan_red.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      anchor: { x: 5, y: 80 },
    }),
    gonglu2_red: new window.TMap.MarkerStyle({
      width: 18, // 点标记样式宽度（像素）
      height: 21, // 点标记样式高度（像素）
      src: require("@/assets/images/icon_gonglu_red.png"), //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      anchor: { x: 5, y: 80 },
    }),
  };
  let info = document.getElementById("info");
  // var info = this.$refs.mapInfo;
  //初始化地图
  map = new window.TMap.Map(info, {
    zoom: oldZoomed, //设置地图缩放级别
    center: new window.TMap.LatLng(22.538484, 113.40031), //设置地图中心点坐标
    mapStyleId: "style1",
    showControl: false, // 是否显示控件
    baseMap: {
      type: "vector",
      // features: ["base", "building3d"], // 隐藏矢量文字
    },
  });
  console.log("map",map)
  if (props.mapData.doing.value) {
    nextTick(() => {
      createInfoWindows(props.mapData.doing.value);
    });
  }
  map.on("zoomend", function () {
    const zoom = map.getZoom();
    //放大且放大到12级别时触发隐藏（zoom越小越大）
    if (zoom <= 12 && zoom < oldZoomed) {
      infoWindows.forEach((item) => {
        item.infoWindow.close();
      });
      oldZoomed = zoom;
    } else if (zoom > 12 && zoom > oldZoomed) {
      //缩小且缩写到12级别时触发显示
      if (infoWindows.length) {
        infoWindows.forEach((item) => {
          if (item.close == false) {
            item.infoWindow.open();
          }
        });
      }
      oldZoomed = zoom;
    }
  });
  infoWindowByClick = new TMap.InfoWindow({
    map: map,
    enableCustom: true,
    offset: { x: 163, y: -32 }, //偏移
    position: new TMap.LatLng(40.040422, 116.273521),
    content: "<div></div>",
  });
  infoWindowByClick.close();
}

//生成正在执行的自定义窗体
function createInfoWindows(data) {
  if (!Array.isArray(data)) {
    data = Array.from(data); // 将proxy对象转换为数组
  }
  //清空保存正在维修的信息窗体的数组
  infoWindows = [];
  data.forEach((item) => {
    let content = generateInfoWindowContent(item, "red");
    let infoWindow = new TMap.InfoWindow({
      map: map,
      enableCustom: true,
      offset: { x: 163, y: -32 }, //偏移
      position: new TMap.LatLng(item.latitude, item.longitude),
      content: content,
    });
    // 为关闭按钮绑定点击事件
    let closeBtn = infoWindow.dom.querySelector(".close");
    infoWindows.push({
      infoWindow: infoWindow,
      close: false,
      ou_id: item.ou_id,
    });
    closeBtn.addEventListener("click", function () {
      // 关闭信息窗口
      infoWindow.close();
      //假如这个窗口是错误信息窗口，那关闭时还要在他的infoWindows数组里面把对应项的close改为ture
      //获取索引
      let index = infoWindows.findIndex(
        (item) => item.infoWindow === infoWindow
      );
      //修改
      if (index !== -1) {
        infoWindows.splice(index, 1, { ...infoWindows[index], close: true });
      }
    });
  });
}

//生成、删除标记
watch(computedMarkers, (newValue) => {
  if (newValue != null) {
    mapMarker = new window.TMap.MultiMarker({
      map: map,
      styles: labelStyle,
      //点标记数据数组
      geometries: newValue,
    });
    mapMarker.on("click", remarkInfoShow);
  } else {
    // 移除标记
    if (mapMarker) {
      mapMarker.clear();
      mapMarker = null;
    }
  }
});
// watch(computedMarkers, (newValue) => {
//   if (newValue != null) {
//     //创建点聚合对象
//     markerCluster = new TMap.MarkerCluster({
//       id: "cluster", //图层id
//       map: map, //设置点聚合显示在哪个map对象中（创建map的段落省略）
//       enableDefaultStyle: true, //使用默认样式
//       minimumClusterSize: 2, //最小聚合点数：2个
//       geometries: newValue,
//       zoomOnClick: true, //点击聚合数字放大展开
//       gridSize: 60, //聚合算法的可聚合距离，即距离小于该值的点会聚合至一起，默认为60，以像素为单位
//       averageCenter: false, //每个聚和簇的中心是否应该是聚类中所有标记的平均值
//       maxZoom: 13, //采用聚合策略的最大缩放级别，若地图缩放级别大于该值，则不进行聚合，标点将全部被展开
//     });
//   } else {
//     if (markerCluster) {
//       markerCluster.clear();
//       markerCluster = null;
//     }
//   }
// });
//生成自定义信息窗体——正在维修的网点
watch(
  () => props.mapData.doing,
  (newData) => {
    console.log("你好")
    infoWindows.forEach((item) => {
      item.infoWindow.close();
    });
    infoWindows = [];
    let infoWindowContainer = document.getElementsByClassName(
      "my_infowindow_error_container"
    );
    let length = infoWindowContainer.length;
    if (infoWindowContainer.length > 0) {
      for (let i = 0; i < length; i++) {
        document
          .getElementsByClassName("my_infowindow_error_container")[0]
          .remove();
      }
    }
    if (map) {
      createInfoWindows(props.mapData.doing);
    }
  }
);
// 是否展示某个标点
const handleSelectChange = (type) => {
  console.log("是否显示", checkObj);
  let category, addOrDelete;
  if (type == 1) {
    category = "pingan";
    addOrDelete = checkObj.value.pingan ? "add" : "delete";
  }
  if (type == 2) {
    category = "jiaotong";
    addOrDelete = checkObj.value.jiaotong ? "add" : "delete";
  }
  if (type == 3) {
    category = "huanbao";
    addOrDelete = checkObj.value.huanbao ? "add" : "delete";
  }
  if (type == 4) {
    category = "gonglu";
    addOrDelete = checkObj.value.gonglu ? "add" : "delete";
  }
  let operList = computedMarkers.value.filter((item) => {
    return item.styleId == category;
  });
  let operIds = operList.map((item) => item.id);
  updateMapGauge(addOrDelete, operIds, operList);
};

// 隐藏或显示标点图标之外的装饰
const remarkInfoShow = (event) => {
  console.log(event);
  console.log(event.geometry);
  infoWindowByClick.open();
  infoWindowByClick.setPosition(event.geometry.position); //设置信息窗位置
  infoWindowByClick.setContent(event.geometry.myContent); //设置信息窗内容
  if (event.geometry.styleId.indexOf("red") > 0) {
    //假如点击的是正在维修的网点，那么就是要在显示的同时还要在infoWindows数组上改close为false
    let index = infoWindows.findIndex(
      (item) => item.ou_id === event.geometry.id
    );
    //修改
    if (index !== -1) {
      infoWindows.splice(index, 1, { ...infoWindows[index], close: false });
    }
  }
  let closeBtn = infoWindowByClick.dom.querySelector(".close");
  closeBtn.addEventListener("click", function (event) {
    event.stopPropagation();
    // 关闭信息窗口
    infoWindowByClick.close();
    //假如这个窗口是错误信息窗口，那关闭时还要在他的infoWindows数组里面把对应项的close改为ture
    if (event.geometry.styleId.indexOf("red") > 0) {
      //获取索引
      let index = infoWindows.findIndex(
        (item) => item.ou_id === event.geometry.id
      );
      //修改
      if (index !== -1) {
        infoWindows.splice(index, 1, { ...infoWindows[index], close: true });
      }
    }
  });
  // // 获取描述的标点
  // let descPunctuation = labelData.filter((item) => {
  //   return item.styleId == "success_mark";
  // });
};

// 修改地图上绑定标点的数量
const updateMapGauge = (operationsType, operIds, operList) => {
  console.log("点击了显示或隐藏标点", operationsType, operIds, operList);
  // 去掉标点
  if (operationsType == "delete") {
    let time = operIds.length / 100;
    for (let i = 0; i < time; i++) {
      let temp = operIds.splice(0, 100);
      console.error("temp", temp);
      mapMarker.remove(temp);
    }
    // console.log("执行了", mapMarker);
    // mapMarker.remove(operIds);
  }
  // 添加标点
  if (operationsType == "add") {
    let batchSize = 100; // 每次添加标记的批次大小
    let n = Math.ceil(operList.length / 400); // 计算需要循环的次数
    for (let i = 0; i < n; i++) {
      let start = i * 400; // 计算本次循环的起始位置
      let end = Math.min(start + 400, operList.length); // 计算本次循环的结束位置
      let temp = operList.slice(start, end); // 获取本次循环的 ID 数组
      for (let j = 0; j < temp.length; j += batchSize) {
        let batch = temp.slice(j, j + batchSize); // 获取本批次需要添加的标记 ID 数组
        mapMarker.add(batch); // 添加本批次的标记
      }
    }
    // mapMarker.add(operList);
  }
};
//生成自定义信息窗体的content
function generateInfoWindowContent(item, type = "") {
  let category_shortText = item.category_text;
  let category_class = "";
  if (item.category == 1) {
    category_class = "pingan";
  } else if (item.category == 2) {
    category_class = "jiaotong";
  } else if (item.category == 3) {
    category_class = "huanbao";
  } else {
    category_class = "gonglu";
  }
  if (item.category_text == "平安中山") {
    category_shortText = "平安";
  } else if (item.category_text == "交通局") {
    category_shortText = "交通";
  } else if (item.category_text == "公路局") {
    category_shortText = "公路";
  }
  if (type == "red" && !item.ou_id) {
    item.ou_id = item.id;
  }
  let content;
  if (type == "red") {
    content = `<div class="infowindow_container my_infowindow_error_container ${type}"><div class="left"><div class="category_wrapper"><div class="category_icon ${category_class}"></div><div class="category_text" title="${category_shortText}">${category_shortText}</div></div></div><div class="right"><div class="row close">X</div><div class="row"><div class="inner_no" title="网点id">网点id：${item.ou_id}</div><div class="sys_no" title="工单id">工单id：${item.id}</div></div><div class="row"><div class="category_text" title="网点分类">${item.category_text}</div><div class="ip" title="ip">${item.ip_address}</div></div><div class="row"><div class="location_name" title="网点地址">${item.location_name}</div></div></div></div>`;
  } else {
    content = `<div class="infowindow_container my_infowindow_normal_container ${type}"><div class="left"><div class="category_wrapper"><div class="category_icon ${category_class}"></div><div class="category_text" title="${category_shortText}">${category_shortText}</div></div></div><div class="right"><div class="row close">X</div><div class="row"><div class="inner_no" title="网点id">网点id：${item.id}</div></div><div class="row"><div class="category_text" title="网点分类">${item.category_text}</div><div class="ip" title="ip">${item.ip_address}</div></div><div class="row"><div class="location_name" title="网点地址">${item.location_name}</div></div></div></div>`;
  }
  return content;
}
</script>

<style lang="scss">
.map_wrapper {
  &.showDigitaltwin {
    position: absolute;
    top: 0;
    left: 0;
    width: 1920px;
    height: 1080px;
    background-color: rgba(0, 2, 10, 0.9);
    background-image: radial-gradient(
      51.98% 50%,
      rgba(1, 52, 74, 0.5) 0%,
      rgba(0, 1, 28, 0.5) 100%
    );
  }
  .infowindow_container {
    width: 400px;
    height: 110px;
    background-size: contain;
    color: #fff;
    &.normal {
      .left {
        background: url("~@/assets/images/mark_normal_left.png") no-repeat;
        background-size: contain;
        background-position: center;
        background-size: 100% 100%;
      }
      .right {
        background: url("~@/assets/images/mark_normal_right.png") no-repeat;
        background-size: contain;
        background-position: center;
        background-size: 100% 100%;
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    .left {
      display: inline-block;
      width: 130px;
      height: 80px;
      text-align: center;
      position: relative;
      background: url("~@/assets/images/mark_error_left.png") no-repeat;
      background-size: contain;
      background-position: center;
      left: 0%;
      top: 40%;
      .category_wrapper {
        position: absolute;
        left: 16.4%;
        top: 7%;
        display: flex;
        flex-direction: column;
        .category_icon {
          width: 30px;
          height: 30px;
          background-size: contain;
          background-position: center;
          &.pingan {
            background: url("~@/assets/images/icon_pingan_white.png") no-repeat;
            background-size: contain;
            background-position: center;
          }
          &.gonglu {
            background: url("~@/assets/images/icon_gonglu_white.png") no-repeat;
            background-size: contain;
            background-position: center;
          }
          &.huanbao {
            background: url("~@/assets/images/icon_huanbao_white.png") no-repeat;
            background-size: contain;
            background-position: center;
          }
          &.jiaotong {
            background: url("~@/assets/images/icon_jiaotong_white.png")
              no-repeat;
            background-size: contain;
            background-position: center;
          }
        }
      }
    }
    .right {
      /* position: absolute; */
      display: inline-block;
      left: 50%;
      top: 0%;
      width: 240px;

      padding: 14px 14px;
      background: url("~@/assets/images/mark_error_right.png") no-repeat;
      background-size: 100% 100%;
      margin-left: -6px;
    }
    .row.close {
      direction: rtl;
      cursor: pointer;
    }
    .row div {
      display: inline-block;
    }
  }

  .title {
    margin-top: 26px;
    .status-type {
      width: 160px;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.54px;
      line-height: 0px;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: bottom;
      & > .status {
        display: flex;
        width: 50%;
        // justify-content: start;
        align-items: center;
        & > div {
          width: 40px;
          height: 40px;
          background: url("~@/assets/images/error_map.png");
          background-size: 100% 100%;
        }
        &.status-success {
          & > div {
            // border: 2px solid rgba(159, 252, 254, 1);
            // box-shadow: 0px 0px 10px 4px rgba(0, 162, 255, 0.5);
            background: url("~@/assets/images/success_map.png");
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .map {
    width: 100%;
    height: 494px;
    // border: 1px solid rgba(255, 255, 255, 1);
    margin-top: 18px;
    overflow: hidden;
    border: 0.3px solid rgba(44, 179, 255, 0.2);
    position: relative;
    .map-map {
      width: 100%;
      height: 534px;
    }
    .operations {
      width: 100px;
      // height: 99px;
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 100000000;
      top: 10px;
      right: 10px;
      // background: rgba(44, 179, 255, 0.2);
      background: rgba(35, 132, 221, 0.2);
      backdrop-filter: blur(20px);
      padding: 4px 6px;
      box-sizing: border-box;

      .check-icon {
        width: 10px;
        height: 10px;
        margin-right: 2px;
      }
    }
    .statisc {
      width: 100%;
      height: 140px;
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(18, 155, 255, 0) 0%,
        rgba(18, 155, 255, 0.8) 100%
      );
      bottom: 0;
      text-align: center;
      .static-box {
        height: 80px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 24px;
        box-sizing: border-box;
        margin-top: 54px;
        .static-box--item {
          width: calc(100% / 3);
          div {
            font-size: 12px;
            // font-weight: 500;
            letter-spacing: 1.2px;
            &:nth-child(1) {
              margin-bottom: 10px;
            }
            color: rgba(255, 255, 255, 1);
            &:nth-child(2) {
              font-size: 40px;
              font-weight: 700;
              color: rgba(255, 255, 255, 1);
            }
          }
        }
        & > .slicer {
          background: rgba(255, 255, 255, 0.2);
          font-size: 20px;
          height: 30px;
          width: 1px;
        }
      }
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: rgba(24, 254, 254, 0.3);
    }
    .el-checkbox__label {
      color: #fff;
    }
    .el-checkbox__inner {
      border-radius: 8px;
      width: 14px;
      height: 14px;
      border: none;
      background-color: rgba(24, 254, 254, 0.3);
      position: relative;
      &::after {
        box-sizing: content-box;
        content: "";
        border-radius: 4px;
        left: 3.5px;
        position: absolute;
        top: 3.4px;
        border: none;
        width: 6.5px;
        height: 6.5px;
        position: absolute;
        line-height: 16px;
        background: rgba(24, 254, 254, 1);
      }
    }
  }
  // .digitaltwin {
  //   width: 100%;
  //   height: calc(100% - 49px);
  //   margin-top: 18px;
  //   overflow: hidden;
  //   border: 0.3px solid rgba(44, 179, 255, 0.2);
  //   position: relative;
  //   .digitaltwin-content {
  //     width: 100%;
  //     height: 100%;
  //     overflow: hidden;
  //     border: none;
  //     &::-webkit-scrollbar {
  //       display: none;
  //     }
  //   }
  // }
}



</style>
