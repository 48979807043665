import axios from 'axios';

import BaseUrl from './baseUrl';

const instance = axios.create({
    baseURL: BaseUrl.baseUrl.prod,
    timeout: 5000
})


// 请求拦截
instance.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error)
})

// 响应拦截
instance.interceptors.response.use(res => {
    if (res.data.code !== 200) {
        return Promise.reject(res.data)
    } else {
        return Promise.resolve(res.data);
    }
}, error => {
    let { response } = error;

    let code = response.data.code;

    switch (code) {
        case 401:
               console.log(error, "请登录") 
            break;
    
        default:
            break;
    }
})

// 导出ins
export default instance;